import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import routePaths, { orderActionTypes } from './route-paths';
import { DesignConcept } from '../pages/design/components/design-concept';
import { DesignModel } from '../pages/design/components/design-model';
import { DesignEngineering } from '../pages/design/components/design-engineering';
import { DesignContent } from '../pages/design/components/design-content';
import Page404 from '../components/404';
import {
  Unauthorized,
  Batch,
  Batches,
  BatchPartCreation,
  Dashboard,
  DashboardReport,
  Design,
  Designer,
  DesignerFeature,
  DesignerInvites,
  Designers,
  Designs,
  Downloads,
  GiftCards,
  GiftCode,
  GiftCodes,
  Inventories,
  Inventory,
  InventoryPurchase,
  InventoryPurchases,
  Job,
  Jobs,
  Locations,
  Machine,
  Machines,
  NewInventoryPurchase,
  NewOrder,
  NewStocks,
  Order,
  Orders,
  Part,
  PartReleaseRate,
  Payout,
  Payouts,
  Press,
  Product,
  ProductReviews,
  Products,
  Referrals,
  SearchPart,
  Stock,
  Stocks,
  StockSummary,
  User,
  Users,
  Vendor,
  Vendors,
} from '../pages';
import Parts from '../pages/parts';
import App from '../app';
import { ProtectedRoute } from '../components/routes';
import { ErrorFallback, errorHandler } from '../components';
import { validRoles } from '../helpers/auth';
import { userRoles } from '../constants/users';
import { getRolesForAccessLevelAndAbove } from '../helpers/get-roles-for-access-level-and-above';
import { primaryStepsForDashboard } from '../constants/dashboards';
import { OrderReassignStock } from '../pages/order-reassign-stock';
import { OrderRefundOrReturn } from '../pages/order-refund-or-return';
import { OrderReplacementReason } from '../pages/order-replacement-reason';

export const routers = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<App />}
      errorElement={
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={errorHandler}
        />
      }
      path="/"
    >
      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={validRoles} Component={SearchPart} />
        }
        path={routePaths.searchPart}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin']}
            Component={Dashboard}
            getUnauthorizedRedirect={({ userType }) => {
              switch (userType) {
                case userRoles.worker:
                  return routePaths.jobs;

                case userRoles.lead:
                  return routePaths.orders;

                default:
                  return undefined;
              }
            }}
          />
        }
        HydrateFallback={Jobs}
        path={routePaths.main}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DashboardReport} />
        }
        loader={async ({ params }) => {
          const isValidPage = primaryStepsForDashboard.some((pageName) => {
            const pageNamePattern = new RegExp(pageName, 'i');

            return pageNamePattern.test(params.dash);
          });

          return isValidPage ? null : redirect(routePaths.main);
        }}
        path={`${routePaths.dashboard}/:dash`}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={NewOrder} />}
        path={routePaths.newOrder}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Orders} />
        }
        path={routePaths.orders}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Order} />
        }
        path={`${routePaths.orders}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={OrderReassignStock}
          />
        }
        path={`${routePaths.orders}/:id/${orderActionTypes.reassignStock}`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={OrderRefundOrReturn}
          />
        }
        path={`${routePaths.orders}/:id/${orderActionTypes.refund}`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={OrderReplacementReason}
          />
        }
        path={`${routePaths.orders}/:id/${orderActionTypes.replacement}`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={OrderRefundOrReturn}
          />
        }
        path={`${routePaths.orders}/:id/${orderActionTypes.return}`}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Users} />}
        path={routePaths.users}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={ProductReviews} />
        }
        path={routePaths.productReviews}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={User} />}
        path={`${routePaths.users}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={Designers} />
        }
        path={routePaths.designers}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Designer} />}
        path={`${routePaths.designers}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DesignerFeature} />
        }
        path={`${routePaths.designers}/:designerId/feature-page/:featureId`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DesignerInvites} />
        }
        path={routePaths.invites}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={Referrals} />
        }
        path={routePaths.referrals}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={GiftCodes} />
        }
        path={routePaths.giftCodes}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={GiftCode} />}
        path={`${routePaths.giftCodes}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={GiftCards} />
        }
        path={routePaths.giftCards}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Jobs} />}
        path={routePaths.jobs}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Job} />}
        path={`${routePaths.jobs}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={validRoles} Component={PartReleaseRate} />
        }
        path={routePaths.partReleaseRate}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Stocks} />}
        path={routePaths.stocks}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Parts} />}
        path={routePaths.parts}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Part} />}
        path={`${routePaths.parts}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Batches} />
        }
        path={routePaths.batches}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Batch} />
        }
        path={`${routePaths.batches}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={BatchPartCreation}
          />
        }
        path={routePaths.batchPartCreation}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={validRoles} Component={Stock} />}
        path={`${routePaths.stocks}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={NewStocks} />
        }
        path={routePaths.newStock}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={validRoles} Component={StockSummary} />
        }
        path={routePaths.stockSummary}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={getRolesForAccessLevelAndAbove(userRoles.worker)}
            Component={Machines}
          />
        }
        path={routePaths.machines}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={getRolesForAccessLevelAndAbove(userRoles.worker)}
            Component={Machine}
          />
        }
        path={`${routePaths.machines}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={Inventories}
          />
        }
        path={routePaths.inventories}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Vendors} />}
        path={routePaths.vendors}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Vendor} />}
        path={`${routePaths.vendors}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={InventoryPurchases}
          />
        }
        path={routePaths.inventoryPurchases}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin']}
            Component={NewInventoryPurchase}
          />
        }
        path={routePaths.newInventoryPurchase}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={InventoryPurchase}
          />
        }
        path={`${routePaths.inventoryPurchases}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={Inventory}
          />
        }
        path={`${routePaths.inventories}/:id`}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Designs} />}
        path={routePaths.designs}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Design} />}
        path={routePaths.design}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DesignConcept} />
        }
        path="/designs/:designId/concept"
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DesignModel} />
        }
        path="/designs/:designId/model"
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin']}
            Component={DesignEngineering}
          />
        }
        path="/designs/:designId/engineering"
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={DesignContent} />
        }
        path="/designs/:designId/content"
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Products} />
        }
        path={routePaths.products}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin', 'Lead']} Component={Product} />
        }
        path={`${routePaths.products}/:id`}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Payouts} />}
        path={routePaths.payouts}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Payout} />}
        path={`${routePaths.payouts}/:id`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute allowedFor={['Admin']} Component={Downloads} />
        }
        path={`${routePaths.downloads}`}
      />

      <Route
        caseSensitive
        element={<ProtectedRoute allowedFor={['Admin']} Component={Press} />}
        path={`${routePaths.press}`}
      />

      <Route
        caseSensitive
        element={
          <ProtectedRoute
            allowedFor={['Admin', 'Lead']}
            Component={Locations}
          />
        }
        path={`${routePaths.locations}`}
      />

      <Route
        caseSensitive
        Component={Unauthorized}
        path={routePaths.unauthorized}
      />

      <Route Component={Page404} path="*" />
    </Route>,
  ),
);
